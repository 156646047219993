<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <v-autocomplete
        v-model="fromTime"
        :items="hours(timeSlot[0], timeSlot[1], true)"
        label="Przedział gozdzinowy"
        placeholder="-"
        :rules="validators"
        color="primary"
        outlined
        dense
      />
      <div class="ma-3">
        &ndash;
      </div>
      <v-autocomplete
        v-model="toTime"
        :items="hours(timeSlot[0], timeSlot[1])"
        placeholder="-"
        :rules="validators"
        color="primary"
        outlined
        dense
      />
    </div>
    <v-text-field
      v-model="notes"
      class="mt-4"
      label="Notatka do przedziału godzinowego"
      placeholder="Wpisz notatkę do przedziału godzinowego"
      outlined
    />
  </div>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import hours from '../../../utils/availableHours'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  data: () => ({
    showPicker: false,
    timeSlot: [null, null],
    timeNotes: ''
  }),
  computed: {
    notes: {
      get() {
        return this.timeNotes
      },
      set(value) {
        this.timeNotes = value
        this.$emit('update', { fromTime: this.timeSlot[0], toTime: this.timeSlot[1], timeNotes: value })
      }
    },
    fromTime: {
      get() {
        return this.timeSlot[0]
      },
      set(time) {
        this.timeSlot = [time, this.timeSlot[1]]
        this.$emit('update', { fromTime: time, toTime: this.timeSlot[1], timeNotes: this.timeNotes })
      }
    },
    toTime: {
      get() {
        return this.timeSlot[1]
      },
      set(time) {
        this.timeSlot = [this.timeSlot[0], time]
        this.$emit('update', { fromTime: this.timeSlot[0], toTime: time, timeNotes: this.timeNotes })
      }
    }
  },
  mounted() {
    this.timeSlot = get(this.data, this.field.value, ['8:00', '18:00'])
    this.timeNotes = get(this.data, 'timeNotes', '')
  },
  methods: {
    hours
  }
}
</script>
